import React from 'react';
import Step from './Step';

type StepType = {
  id: string | number;
  title: string;
  content: string;
};

type StepListProps = {
  items: StepType[];
  className?: string;
};
const StepList = (props: StepListProps) => {
  const { items, className = '' } = props;
  return (
    <ul className={`step-list ${className || ''}`}>
      {items.map((item, idx) => (
        <Step
          key={item.id}
          id={item.id}
          stepNumber={idx + 1}
          isSelected={false}
          title={item.title}
          content={item.content}
        />
      ))}
    </ul>
  );
};

StepList.defaultProps = {
  className: '',
};

export default StepList;
