import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import ArrowLeft from '/static/svg/arrow-left.svg';
import ArrowRight from '/static/svg/arrow-right.svg';

type CarouselItem = {
  items: string[];
};

type ColumnsCarousel = {
  items: CarouselItem[];
};

const ColumnsCarousel = ({ items }: ColumnsCarousel) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 769 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 577 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="columns-carousel">
      <h2 className="columns-carousel__title">
        talents from all tech disciplines
      </h2>
      <Carousel
        responsive={responsive}
        swipeable
        arrows
        customLeftArrow={<ArrowLeft className="arrow-left" />}
        customRightArrow={<ArrowRight className="arrow-right" />}
        showDots
        partialVisible
        dotListClass="custom-dots"
      >
        {items.map((slide, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="columns-carousel__slide" key={i}>
            <ul className="carousel-list">
              {slide.items.map((item) => (
                <li className="carousel-list__item" key={item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ColumnsCarousel;
