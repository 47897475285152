import React from 'react';

type SectionTitleProps = {
  title: string;
  subTitle?: string;
  description?: string;
};

const SectionTitle = ({ title, subTitle, description }: SectionTitleProps) => (
  <div className="section-title">
    <h2 className="section-title__title">{title}</h2>
    {subTitle && <h3 className="section-title__subTitle">{subTitle}</h3>}
    {description && <p className="section-title__description">{description}</p>}
  </div>
);

SectionTitle.defaultProps = {
  subTitle: '',
  description: '',
};

export default SectionTitle;
