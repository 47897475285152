import React from 'react';
import SectionTitle from '@/components/common/SectionTitle';

type SectionProps = {
  title?: string;
  subTitle?: string;
  description?: string;
  className?: string;
  children: React.ReactNode;
  id?: string;
};
const Section = ({
  title,
  subTitle,
  description,
  className,
  children,
  id,
}: SectionProps) => (
  <section className={`section ${className}`} id={id}>
    <div className="section__wrap">
      {(title || subTitle || description) && (
        <div className="section__header">
          <SectionTitle
            title={title}
            subTitle={subTitle}
            description={description}
          />
        </div>
      )}
      {children && <div className="section__content">{children}</div>}
    </div>
  </section>
);

Section.defaultProps = {
  title: '',
  subTitle: '',
  description: '',
  className: '',
  id: '',
};

export default Section;
