import React from 'react';
import CTA from '@/components/common/CTA';
import HeroCover from '../../../../static/images/hero.png';

const HeroSection = () => (
  <div className="hero-section">
    <div className="hero-section__wrap">
      <div className="hero-section__content">
        <h2 className="hero-section__title">
          we help you to create a well-balanced, remote product team
        </h2>
        <p className="hero-section__description">
          We care about the perfect combination of functional expertise, as well
          as personality and cultural fit.
        </p>
        <CTA label="Get your team" linkTo="/contact" green />
      </div>

      <img
        className="hero-section__cover"
        src={HeroCover}
        alt="get your team"
      />
    </div>
  </div>
);

export default HeroSection;
