import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '@/components/Layout';
import Header from '@/components/common/Header';
import HowSection from '@/sections/Home/HowSection';
import WhatSection from '@/sections/Home/WhatSection';
import WhoSection from '@/sections/Home/WhoSection';
import HeroCTA from '@/components/common/HeroCTA';
import CTA from '@/components/common/CTA';
import Footer from '@/components/common/Footer';
import HeroSection from '@/sections/Home/HeroSection';
import PhraseSection from '@/sections/Home/PhraseSection';

function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Get Your Team</title>
        <meta name="description" content="Get your team" />
      </Helmet>
      <Header />
      <HeroSection />
      <HowSection />
      <PhraseSection />
      <WhatSection />
      <WhoSection />
      <HeroCTA text="Get your team today">
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <CTA label="Get your team" linkTo="/contact" />
      </HeroCTA>
      <Footer />
    </Layout>
  );
}

export default Home;
