import React from 'react';
import StepList from '@/components/common/steps/StepList';
import Section from '@/components/Layout/section';

const HowSection = () => {
  const items = [
    {
      id: '699db968-e810-4a7f-a770-63b39a76f254',
      title: 'analysis',
      content:
        'We analyse your needs in terms of TechStack, Seniority, Current Project status, Project Roadmap/Vision. ',
    },
    {
      id: '2f76c5b2-acd8-46f8-b653-f0e4f96793fe',
      title: 'team set up proposal',
      content:
        'We search for the right team members according to your needs and based on our expertise of matching teams on many levels. ',
    },
    {
      id: '09d51a5c-f005-4ca2-82d6-4bcf2e609abc',
      title: 'kick-off',
      content: 'The new team members are introduced to you.',
    },
    {
      id: 'bd01d60b-cae2-4d3d-998f-7e6f48941dea',
      title: 'follow up',
      content:
        'Success Mangament - Monthly meetings, consultations of technical solutions, constant feedback. ',
    },
  ];
  return (
    <Section
      className="who-section"
      title="who"
      id="who"
      subTitle="trust our process"
      description="We built the process to get your team with the highest quality standards"
    >
      <StepList className="who-section__content" items={items} />
    </Section>
  );
};

export default HowSection;
