import React from 'react';
import Section from '@/components/Layout/section';

const PhraseSection = () => (
  <Section className="phrase-section">
    <blockquote className="phrase-section__quote">
      “Talent wins games, but teamwork wins championships.” –{' '}
      <small className="phrase-section__author">Michael Jordan</small>
    </blockquote>
  </Section>
);

export default PhraseSection;
