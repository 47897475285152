import React from 'react';

export type StepProps = {
  isSelected: boolean;
  id: number | string;
  title: string;
  content: string;
  stepNumber: number;
};

const Step = (props: StepProps) => {
  const { title, content, stepNumber } = props;
  return (
    <li className="step">
      <span className="step__header">
        <h4 className="step__number">Step {stepNumber}</h4>
        <span className="step__bullet" />
      </span>
      <span className="step__content">
        <h3 className="step__title">{title}</h3>
        <p className="step__description">{content}</p>
      </span>
    </li>
  );
};

export default Step;
