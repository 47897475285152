/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

// @ts-ignore
import MapFigure from '../../../../static/images/map.svg';
/* eslint-enable @typescript-eslint/ban-ts-comment */

const HubsMap = () => (
  <div className="hubs-map">
    <div className="hubs-map__content">
      <h3 className="hubs-map__title">tech hubs all over the world</h3>
      <p className="hubs-map__description">
        We have our roots in three different continents and can anable you to
        work with teams in several countries and diffrent timezones.
      </p>
    </div>

    <MapFigure />
  </div>
);

export default HubsMap;
