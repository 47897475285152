/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Section from '@/components/Layout/section';

import IconColumns from '@/components/common/IconColumns';
import ColumnsCarousel from '@/components/ColumnsCarousel';
import HubsMap from '@/components/common/HubsMap';
// @ts-ignore
import SearchIcon from '../../../../static/svg/search.svg';
// @ts-ignore
import HandshakeIcon from '../../../../static/svg/handshake.svg';
// @ts-ignore
import ScreenIcon from '../../../../static/svg/screen.svg';
// @ts-ignore
/* eslint-enable @typescript-eslint/ban-ts-comment */

const OfferSection = () => {
  const columnItems = [
    {
      Icon: SearchIcon,
      title: 'talent screening & recruting',
      description:
        'We source your specific candidates or your whole team from our talent pool and present you within 10 working days.',
    },
    {
      Icon: HandshakeIcon,
      title: 'full employment care',
      description:
        'We take care of all legal, financial and regulatory matters of your future employees. You only have a contract with us as your service partner.',
    },
    {
      Icon: ScreenIcon,
      title: 'workspace and technical support',
      description:
        'We provide all the necessary support for your remote team, from workspace (home-office) over laptops and screens to technical support.',
    },
  ];

  const carouselColumnItems = [
    {
      items: [
        'Frontend Engineer',
        'Backend Engineer',
        'Full Stack Developer',
        'Android Developer',
        'iOS Developer',
      ],
    },
    {
      items: [
        'Data Analyst',
        'Data Scientist',
        'Machine Learning Expert',
        'AI Engineer',
        'QA Engineer',
      ],
    },
    {
      items: [
        'Engineering Manager',
        'Network Engineer',
        'Network Admin',
        'DevOps Engineer',
        'Other roles',
      ],
    },
  ];

  return (
    <Section
      className="offer-section"
      title="how"
      id="how"
      subTitle="we offer you"
      description={`Our many years experience of working in
    multicultural teams, located across the globe.`}
    >
      <IconColumns items={columnItems} />
      <ColumnsCarousel items={carouselColumnItems} />
      <HubsMap />
    </Section>
  );
};

export default OfferSection;
