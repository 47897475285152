import React from 'react';
import Section from '@/components/Layout/section';
import OvalsCarousel from '@/components/OvalsCarousel';

import 'react-multi-carousel/lib/styles.css';

const WhatSection = () => (
  <Section
    title="what"
    subTitle="we create better teams"
    description="Our teams are able to perform above average and are ready to master the challenges of tomorrow, because their members are chosen to be complementary on three different levels. "
    className="what-section"
    id="what"
  >
    <article className="two-columns two-columns--reversed">
      <figure className="two-columns__cover">
        <img
          className="two-columns__picture"
          src="/images/what-zero.png"
          alt="personality fit"
        />
      </figure>
      <div className="two-columns__content">
        <h4 className="two-columns__title">personality fit</h4>
        <h5 className="two-columns__subtitle">the technology type</h5>
        <p className="two-columns__description">
          The functional expertise is the basis for our recruiting process. We
          examine it on the way of an extensive interview and technical tests.
          We analyse, among others, individual tech stack, familiarity with
          working frameworks as well as the length and type of an experience.
          Finally, we identify “the technology type”.
        </p>
      </div>
    </article>
    <OvalsCarousel />
    <article className="two-columns">
      <figure className="two-columns__cover">
        <img
          className="two-columns__picture"
          src="/images/what-one.png"
          alt="personality fit"
        />
      </figure>
      <div className="two-columns__content">
        <h4 className="two-columns__title">personality fit</h4>
        <h5 className="two-columns__subtitle">emotional intelligence</h5>
        <p className="two-columns__description">
          In the second step we focus on choosing complementary personality
          types for a team. To do so we use several psychological evaluation
          tools and foremost we concentrate on examining emotional intelligence
          on the way of a behavioral interview.
        </p>
      </div>
    </article>

    <article className="two-columns two-columns--reversed">
      <figure className="two-columns__cover">
        <img
          className="two-columns__picture"
          src="/images/what-two.png"
          alt="cultural fit"
        />
      </figure>
      <div className="two-columns__content">
        <h4 className="two-columns__title">cultural fit </h4>
        <h5 className="two-columns__subtitle">Multicultural teams</h5>
        <p className="two-columns__description">
          We are aware of the significant influence of culture on international
          collaboration, in terms of communication, decision making, time
          management, feedback style, organisation structure and more. We
          prepare and educate our teams in that topic along the whole process.
        </p>
      </div>
    </article>
  </Section>
);

export default WhatSection;
