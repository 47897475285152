import React from 'react';
import Carousel from 'react-multi-carousel';
import OvalSlide from '@/components/OvalsCarousel/OvalSlide';

import Portrait from '/static/images/portrait.png';
import Portrait2 from '/static/images/portrait2.png';
import Portrait3 from '/static/images/portrait3.png';
import ArrowLeft from '/static/svg/arrow-left.svg';
import ArrowRight from '/static/svg/arrow-right.svg';

const OvalsCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 769 },
      items: 0,
    },
    tablet: {
      breakpoint: { max: 768, min: 577 },
      items: 0,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="ovals-carousel">
      <Carousel
        responsive={responsive}
        swipeable
        arrows
        showDots
        partialVisible
        dotListClass="custom-dots"
        customLeftArrow={<ArrowLeft className="arrow-left" />}
        customRightArrow={<ArrowRight className="arrow-right" />}
      >
        <OvalSlide
          title="Hacker"
          subTitle="Problem Solver"
          Image={Portrait}
          classModifier="blue"
        />
        <OvalSlide
          title="Star Architect"
          subTitle="PO-Proxy"
          Image={Portrait2}
          classModifier="green"
        />
        <OvalSlide
          title="Coding Machine"
          subTitle="Loves to write beautiful code"
          Image={Portrait3}
          classModifier="purple"
        />
      </Carousel>
    </div>
  );
};

export default OvalsCarousel;
