import React from 'react';
import classnames from 'classnames';

type OvalSlideProps = {
  title: string;
  subTitle: string;
  classModifier?: string;
  Image: any;
};

const OvalSlide = ({
  title,
  subTitle,
  Image,
  classModifier = '',
}: OvalSlideProps) => {
  const cx = classnames('oval-slide', {
    [`oval-slide--${classModifier}`]: classModifier,
  });
  return (
    <div className={cx}>
      <div className="oval-slide__inner">
        <img className="oval-slide__image" src={Image} alt={title} />
        <div className="oval-slide__info">
          <p className="oval-slide__title">{title}</p>
          <p className="oval-slide__subTitle">{subTitle}</p>
        </div>
      </div>
    </div>
  );
};

OvalSlide.defaultProps = {
  classModifier: '',
};

export default OvalSlide;
