import React from 'react';

type HeroCTAProps = {
  text: string;
  children?: React.ReactNode;
};

const HeroCTA = ({ text, children }: HeroCTAProps) => (
  <div className="hero-cta">
    {text && <div className="hero-cta__text">{text}</div>}
    {children}
  </div>
);

HeroCTA.defaultProps = {
  children: null,
};

export default HeroCTA;
