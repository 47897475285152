import React from 'react';

type ColumnItems = {
  Icon: any;
  title: string;
  description: string;
};

type IconColumns = {
  items: ColumnItems[];
};

const IconColumns = ({ items }: IconColumns) => (
  <div className="icon-columns">
    {items.map(({ title, description, Icon }) => (
      <div key={`${title}`} className="icon-column">
        <div className="icon-column__wrapper">
          <Icon className="icon-column__icon" />
        </div>
        <h3 className="icon-column__title">{title}</h3>
        <p className="icon-column__description">{description}</p>
      </div>
    ))}
  </div>
);

export default IconColumns;
